import {useContext} from 'react';
import AppContext from '../AppContext';
import {getProp, getOldSegmentId} from '../../lib/utils';

export const getOverwritesData = (pageData, type) => {
    const getDataByType = (type) => {
        const acfData = pageData?.acf?.content_segment_cta_overwrites || {};
        const title = getProp(acfData, `${type}_cta.title`, '');
        const content = getProp(acfData, `${type}_cta.content`, '');
        const button_option = getProp(acfData, `${type}_cta.button_option`, 'phone');
        const custom_button = getProp(acfData, `${type}_cta.custom_button`, {});
        const custom_button_text = getProp(custom_button, `custom_button_text`, '');
        const custom_button_link = getProp(custom_button, `custom_button_link`, '');
        const layout_option = getProp(acfData, `${type}_cta.layout_option`, 'standard');
        const background_image = getProp(acfData, `${type}_cta.background_image`, '');

        return {
            title,
            content,
            button_option,
            custom_button: {
                custom_button_text,
                custom_button_link,
            },
            layout_option,
            background_image,
        };
    };

    switch (type) {
        case 'phone':
            return getDataByType('phone');
        case 'value-prop':
            return getDataByType('value_prop');
        default:
            return {};
    }
};

export const getDefaultData = (segment, type) => {
    let segmentName = segment || '';
    segmentName = getOldSegmentId(segmentName);

    const typeName = type || '';

    const {acfOptions = []} = useContext(AppContext);
    const defaults = getProp(acfOptions, 'acf.content_segment_cta_defaults', {});

    const segmentDefaults = getProp(defaults, segmentName);

    switch (typeName) {
        case 'phone':
            return getProp(segmentDefaults, 'phone_cta');
        case 'insurance':
            return getProp(segmentDefaults, 'insurance_cta');
        case 'value-prop':
            return getProp(segmentDefaults, 'value_prop_cta');
        default:
            return {};
    }
};
