import {useContext} from 'react';
import Image from 'next/image';
import Button from 'aac-components/components/Button';
import FacilityCardSlider from '../FacilityCardSlider';
import {
    FacilitySliderCtaContainerStyled,
    LogoStyled,
    H2Styled,
    TextStyled,
    ButtonStyled,
    FacilitySliderContainerStyled,
} from './FacilitySliderCtaStyles';
import AppContext from '../AppContext';
import MaxWidth from '../MaxWidth';

const Variation1 = () => {
    const {isMobile} = useContext(AppContext);
    return (
        <MaxWidth>
            <FacilitySliderCtaContainerStyled>
                <LogoStyled>
                    <Image
                        src="https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/aac-lockup-horizontal-color-v2.svg"
                        style={{objectFit: 'contain'}}
                        alt={'American Addiction Centers'}
                        sizes="100vw"
                        fill
                        unoptimized
                    />
                </LogoStyled>
                <H2Styled>Nationwide Recovery, Wherever You Are</H2Styled>
                <TextStyled>
                    Find expert care at one of AAC’s premier facilities across the U.S and
                    start your recovery today.
                </TextStyled>
                <FacilitySliderContainerStyled>
                    <FacilityCardSlider showViewAllButton={false} showFilter={false} />
                </FacilitySliderContainerStyled>
                <ButtonStyled>
                    <Button
                        title="View All Treatment Facilities"
                        href="/treatment-centers">
                        {isMobile
                            ? 'View All Treatment Facilities'
                            : 'View More Treatment Facilities'}
                    </Button>
                </ButtonStyled>
            </FacilitySliderCtaContainerStyled>
        </MaxWidth>
    );
};

export default Variation1;
