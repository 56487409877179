import styled from 'styled-components';
import {BREAKPOINT} from 'aac-components/lib/styles';

export const FacilitySliderCtaContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    text-align: center;
`;

export const FacilitySliderCtaContainerV2Styled = styled.div`
    background: var(--primary-100);
    border: 2px solid var(--primary-300);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    text-align: center;
    padding: 10px;
`;

export const FacilitySliderCtaContainerV3Styled = styled.div`
    border: 4px solid var(--primary-100);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    text-align: center;
    padding: 10px;
`;

export const FacilitySliderCtaContainerV4Styled = styled.div`
    border: 1px solid var(--secondary-300);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 328px;
    text-align: center;
    width: 100%;

    padding: 0 10px;

    @media screen and (min-width: ${BREAKPOINT}) {
        height: 224px;
    }
`;

export const LogoStyled = styled.div`
    position: relative;
    height: 32px;
    min-width: 260px;
    margin: 0 0 10px 0;

    @media screen and (min-width: ${BREAKPOINT}) {
        margin: 10px 0 10px 0;
		height 32px;
    }
`;

export const LogoV3Styled = styled.div`
    position: relative;
    height: 32px;
    min-width: 260px;
    margin-bottom: 10px;
`;

export const H2Styled = styled.p`
    margin: 0 0 10px 0 !important;
    width: 370px;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;

    @media screen and (min-width: ${BREAKPOINT}) {
        width: 100%;
    }
`;

export const H2V4Styled = styled.p`
    font-size: 20px;
    margin: 0 0 15px 0 !important;
    line-height: 32px;
    height: 64px;
    z-index: 1;
    font-weight: bold;

    @media screen and (min-width: ${BREAKPOINT}) {
        height: 32px;
    }
`;

export const TextStyled = styled.div`
    margin-bottom: 25px;
    width: 345px;
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: ${BREAKPOINT}) {
        width: 100%;
    }
`;

export const TextV2Styled = styled.div`
    margin-bottom: 25px;
    width: 315px;
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: ${BREAKPOINT}) {
        width: 100%;
    }
`;

export const TextV3Styled = styled.div`
    margin-bottom: 15px;
    font-size: 12px;
`;

export const TextV4Styled = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 6px;
    height: 120px;
    margin-bottom: 10px;
    z-index: 1;

    @media screen and (min-width: ${BREAKPOINT}) {
        height: 48px;
    }
`;

export const FacilitySliderContainerStyled = styled.div`
    width: 100%;
    border-radius: 8px;
`;

export const ButtonStyled = styled.div`
    margin: 20px 0px 10px 0px;
    width: 100%;
`;

export const ButtonV3Styled = styled.div`
    margin: 0 10px !important;
    width: 100%;
`;

export const ButtonV4Styled = styled.div`
    margin-top: 10px;
    width: 100%;
    z-index: 1;
`;

export const LeftStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    text-align: center;
    padding: 10px;
    width: 100%;

    @media screen and (min-width: ${BREAKPOINT}) {
        width: 403px;
    }
`;

export const RightStyled = styled.div`
    height: 200px;
    width: 313px;
    border-radius: 8px;
    display: flex;
`;

export const MobileRightStyled = styled.div`
    margin-bottom: 15px;
`;

export const ImageContainerStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 328px;
    border-radius: 8px;

    @media screen and (min-width: ${BREAKPOINT}) {
        height: 224px;
    }
`;
