import {useContext} from 'react';
import Image from 'next/image';
import Button from 'aac-components/components/Button';
import {
    FacilitySliderCtaContainerV3Styled,
    H2Styled,
    LeftStyled,
    RightStyled,
    TextV3Styled,
    ButtonV3Styled,
    LogoV3Styled,
    MobileRightStyled,
} from './FacilitySliderCtaStyles';
import AppContext from '../AppContext';
import MaxWidth from '../MaxWidth';

const Variation3 = () => {
    const {isMobile} = useContext(AppContext);
    return (
        <MaxWidth>
            <FacilitySliderCtaContainerV3Styled>
                <LeftStyled>
                    <LogoV3Styled>
                        <Image
                            src="https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/aac-lockup-horizontal-color-v2.svg"
                            style={{objectFit: 'contain'}}
                            alt={'American Addiction Centers'}
                            height={32}
                            width={200}
                            unoptimized
                        />
                    </LogoV3Styled>
                    <H2Styled>Nationwide Recovery, Wherever You Are</H2Styled>
                    <TextV3Styled>
                        Find expert care at one of AAC’s premier facilities across the U.S
                        and start your recovery today.
                    </TextV3Styled>
                    {isMobile && (
                        <MobileRightStyled>
                            <Image
                                src="/static/AAC2-6973/hands-over-heart.png"
                                width={313}
                                height={200}
                                style={{
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    borderRadius: '8px',
                                }}
                            />
                        </MobileRightStyled>
                    )}
                    <ButtonV3Styled>
                        <Button
                            title="Find a Treatment Center Today"
                            href="/treatment-centers">
                            Find a Treatment Center Today
                        </Button>
                    </ButtonV3Styled>
                </LeftStyled>
                {!isMobile && (
                    <RightStyled>
                        <Image
                            src="/static/AAC2-6973/hands-over-heart.png"
                            width={313}
                            height={200}
                            style={{
                                objectFit: 'cover',
                                overflow: 'hidden',
                                borderRadius: '8px',
                            }}
                        />
                    </RightStyled>
                )}
            </FacilitySliderCtaContainerV3Styled>
        </MaxWidth>
    );
};

export default Variation3;
