import Button from 'aac-components/components/Button';
import CallrailButton from '../CallrailButton';
import {BREAKPOINT} from '../../lib/styles';
import Image from 'next/image';
import {withRouter} from 'next/router';
import {replaceDynamicKeywords} from '../../lib/dynamic-keywords';
import React from 'react';

const SegmentCtaShortcode = ({data, dynamicKeywords}) => {
    const title = data?.title || 'Don’t wait. Call us now.';
    const content =
        data?.content ||
        'Our admissions navigators are available to help 24/7 to discuss treatment.';
    const buttonOption = data?.button_option || 'phone';
    const customButton = data?.custom_button || {};
    const customButtonText = customButton?.custom_button_text || 'Verify Insurance';
    const customButtonLink = customButton?.custom_button_link || '/verify-insurance';
    const backgroundImage = data?.background_image || false;

    const dynamicTitle = replaceDynamicKeywords(title, dynamicKeywords);
    const dynamicContent = replaceDynamicKeywords(content, dynamicKeywords);

    return (
        <React.Fragment>
            <div className="segment-cta" data-layout="standard">
                {backgroundImage && (
                    <Image
                        src={backgroundImage}
                        fill
                        style={{objectFit: 'cover'}}
                        alt="American Addiction Centers Photo"
                        sizes={`(min-width: ${BREAKPOINT}) 100vw, 770px`}
                    />
                )}
                <div className="segment-cta__inner">
                    <div
                        className="segment-cta__title"
                        dangerouslySetInnerHTML={{__html: dynamicTitle}}
                    />
                    <div
                        className="segment-cta__content"
                        dangerouslySetInnerHTML={{__html: dynamicContent}}
                    />
                    <div className="segment-cta__button">
                        {buttonOption === 'custom' && (
                            <a href={customButtonLink} title={customButtonText}>
                                <Button theme="primary">{customButtonText}</Button>
                            </a>
                        )}
                        {buttonOption === 'phone' && (
                            <CallrailButton
                                color={backgroundImage ? '#fff' : 'var(--gray-500)'}
                            />
                        )}
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .segment-cta {
                        background: var(--secondary-100);
                        border: 1px solid
                            ${backgroundImage
                                ? 'var(--primary-200)'
                                : 'var(--secondary-200)'};
                        color: ${backgroundImage ? '#fff' : 'var(--gray-500)'};
                        border-radius: 4px;
                        text-align: center;
                        margin: 30px 0;
                        display: block;
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                    }
                    .segment-cta__inner {
                        padding: 30px 15px;
                        background: ${backgroundImage
                            ? 'rgba(000, 000, 000, 0.5)'
                            : 'transparent'};
                        position: relative;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .segment-cta__inner {
                            padding: 30px;
                        }
                    }
                    .segment-cta__title {
                        font-weight: bold;
                        font-size: 20px;
                        position: relative;
                    }
                    .segment-cta__content {
                        max-width: 515px;
                        font-size: 16px;
                        line-height: 30px;
                        margin: 30px auto;
                        position: relative;
                    }
                    .segment-cta.has-image {
                        border: none;
                        background: rgba(000, 000, 000, 0.6);
                        color: #fff;
                        position: relative;
                    }
                    .segment-cta__button {
                        width: fit-content;
                        max-width: 100%;
                        margin: 0 auto;
                    }
                    .provider {
                        text-transform: capitalize;
                    }
                `}
            </style>
        </React.Fragment>
    );
};
export default withRouter(SegmentCtaShortcode);
