import {BREAKPOINT} from '../../lib/styles';
import Button from 'aac-components/components/Button';
import CallrailButton from '../CallrailButton';
import Image from 'next/image';
import {replaceDynamicKeywords} from '../../lib/dynamic-keywords';

const SegmentCtaSplit = ({data, dynamicKeywords}) => {
    const title = data?.title || 'Don’t wait. Call us now.';
    const content =
        data?.content ||
        'Our admissions navigators are available to help 24/7 to discuss treatment.';
    const button_option = data?.button_option || 'phone';
    const customButton = data?.custom_button || {};
    const custom_button_text = customButton?.custom_button_text || 'Verify Insurance';
    const custom_button_link = customButton?.custom_button_link || '/verify-insurance';
    const background_image = data?.background_image || '/static/cta_image.jpg';

    const dynamicTitle = replaceDynamicKeywords(title, dynamicKeywords);
    const dynamicContent = replaceDynamicKeywords(content, dynamicKeywords);

    return (
        <div className="segment-cta" data-layout="split">
            <div className="segment-cta__inner">
                <div className="segment-cta__image">
                    <Image
                        src={background_image}
                        fill
                        alt="American Addiction Centers Photo"
                        style={{objectFit: 'cover'}}
                    />
                </div>
                <section className="segment-cta__content-container">
                    <div
                        className="segment-cta__title"
                        dangerouslySetInnerHTML={{__html: dynamicTitle}}
                    />
                    <div
                        className="segment-cta__content"
                        dangerouslySetInnerHTML={{__html: dynamicContent}}
                    />
                    <div className="segment-cta__button">
                        {button_option === 'custom' && (
                            <a href={custom_button_link} title={custom_button_text}>
                                <Button theme="primary" style={{width: '100%'}}>
                                    {custom_button_text}
                                </Button>
                            </a>
                        )}
                        {button_option === 'phone' && (
                            <CallrailButton style={{width: '100%'}} />
                        )}
                    </div>
                </section>
            </div>
            <style jsx>
                {`
                    button {
                        background: var(--secondary-300);
                    }
                    button:hover {
                        background: var(--secondary-400);
                    }
                    .segment-cta__button {
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .segment-cta__button {
                            max-width: fit-content;
                        }
                    }
                    :global(.segment-cta__content > a) {
                        color: var(--interactive-300);
                        font-weight: bold;
                        text-decoration: none;
                    }
                    .segment-cta {
                        background: var(--secondary-100);
                        border: 2px solid var(--secondary-200);
                        border-radius: 8px;
                        margin: 30px 0;
                        padding: 24px;
                        margin: 40px 0;
                    }

                    .segment-cta__title {
                        color: var(--primary-400);
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    .segment-cta__content {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                    .segment-cta__inner {
                        display: grid;
                        grid-template-columns: 1;
                        grid-gap: 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .segment-cta__inner {
                            grid-template-columns: 160px auto;
                            grid-gap: 40px;
                        }
                    }
                    .segment-cta__image {
                        width: 100%;
                        height: 160px;
                        position: relative;
                        border-radius: 8px;
                        overflow: hidden;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .segment-cta__image {
                            height: 100%;
                        }
                    }
                    .segment-cta {
                        background: var(--tertiary-200);
                        border: 2px solid var(--tertiary-400);
                    }
                    .segment-cta__title {
                        color: var(--gray-500);
                    }
                    button {
                        background: var(--interactive-300);
                    }
                    button:hover {
                        background: var(--interactive-400);
                    }
                `}
            </style>
        </div>
    );
};
export default SegmentCtaSplit;
