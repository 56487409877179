import AppContext from '../AppContext';
import {useContext, useEffect, useRef} from 'react';
import {getProp, getTags} from '../../lib/utils';
import {getOverwritesData, getDefaultData} from './getCtaData';
import SegmentCtaSplitLayout from './SegmentCtaSplitLayout';
import SegmentCtaStandardLayout from './SegmentCtaStandardLayout';
import VeteransCta from '../VeteransCta';

const SegmentCta = ({pageData = {}, prefooter = false}) => {
    const {dynamicKeywords = {}} = useContext(AppContext);

    // these are different because the name in aac wordpress is different from standard cross product
    const segment = pageData?.acf?.content_segment;

    const type = prefooter ? 'phone' : 'value-prop'; // previous db names repurposed

    const ref = useRef();

    useEffect(() => {
        window?.lazyLoadInstance?.update();
    }, []);

    // find out from acf if overwrites should be used
    const shouldUseOverwrite = (cta) => {
        const useOverwrites = getProp(
            pageData,
            `acf.content_segment_cta_overwrites.${cta}_cta.use_cta_overwrites`,
            false,
        );
        return useOverwrites;
    };

    // find out if we should show default or overwrites for each CTA
    const usePreFooterOverwrites = shouldUseOverwrite('phone'); // aka "prefooter"
    const useShortcodeOverwrites = shouldUseOverwrite('value_prop'); // aka "shortcode"

    // get either default data or overwrite data
    const getData = (useOverwrites) => {
        return useOverwrites
            ? getOverwritesData(pageData, type)
            : getDefaultData(segment, type);
    };

    const useSplitLayout = (data) => {
        return data?.layout_option === 'split';
    };

    let data = {};

    /**
     * For each cta, the 'split' design is the same.
     * So if 'split' layout is selected, render that component -
     * otherwise use the phone/insurance/value-prop style
     */
    const renderCta = () => {
        data = prefooter
            ? getData(usePreFooterOverwrites)
            : getData(useShortcodeOverwrites);

        const tags = getTags();
        if (Array.isArray(tags) && tags.includes('veterans')) {
            return (
                <div style={{marginBottom: '40px'}}>
                    <VeteransCta objectPosition="-275px" />
                </div>
            );
        }

        return useSplitLayout(data) ? (
            <SegmentCtaSplitLayout data={data} dynamicKeywords={dynamicKeywords} />
        ) : (
            <SegmentCtaStandardLayout data={data} dynamicKeywords={dynamicKeywords} />
        );
    };

    return <div ref={ref}>{renderCta()}</div>;
};
export default SegmentCta;
